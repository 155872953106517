import { render, staticRenderFns } from "./LiveScript.vue?vue&type=template&id=892d27ba&scoped=true"
import script from "./LiveScript.vue?vue&type=script&lang=js"
export * from "./LiveScript.vue?vue&type=script&lang=js"
import style0 from "./LiveScript.vue?vue&type=style&index=0&id=892d27ba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "892d27ba",
  null
  
)

export default component.exports